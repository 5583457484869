import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { Driver } from "@trackcode/map";

class ScopeDrivers extends Component {
  static propTypes = {
    /** clear scope options */
    getBounds: PropTypes.func.isRequired,
    driverLocationsLoaded: PropTypes.func.isRequired,
    locationsLoaded: PropTypes.bool.isRequired
  };

  static propTypes = {
    drivers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    locations: PropTypes.shape({}).isRequired
  };

  constructor(props) {
    super(props);
    this.driverLocationsLoaded = false;
    // re-render component every minute
    this.refreshDriversInterval = setInterval(this.refreshDrivers, 1000 * 60);
  }

  componentWillUnmount() {
    clearInterval(this.refreshDriversInterval);
  }

  getDriversProps() {
    const { drivers, locations, getBounds } = this.props;
    const bounds = [];
    const driverIds = Object.keys(locations);

    const driversProps = driverIds
      .map(driverId => {
        const driverLocation = locations[driverId];
        const [location] = driverLocation;

        if (!location) return null;

        const lastSignal = moment(location.client_datetime);
        // show driver as inactive after 10 minutes
        const minutesAgo = moment().subtract(10, "minutes");

        // only show drivers with a signal today
        if (!lastSignal.isSame(moment(), "day")) return null;

        // driver information
        const driver = drivers.find(
          ({ id }) => Number(driverId) === Number(id)
        );

        // TC-607 Fix issue if driver not exists in logistic company anymore.
        // This happens when a user switched between logistic companies.
        if (!driver) return null;

        const props = {
          active: lastSignal.isAfter(minutesAgo), // show driver as inactive after 10 minutes
          title: driver ? `${driver.firstname} ${driver.lastname}` : "",
          position: {
            lat: Number(location.latitude),
            lng: Number(location.longitude)
          }
        };

        if (driver.online === 0) {
          // do not show driver as active on map
          // props.active = false;
        }

        bounds.push(props.position);
        return props;
      })
      .filter(Boolean);

    getBounds(bounds);

    return driversProps;
  }

  /**
   * Update drivers regardless of "location_create" sockets every minute to check active status (TC-599).
   */
  refreshDrivers = () => {
    this.forceUpdate();
  };

  render() {
    const { locationsLoaded, driverLocationsLoaded } = this.props;

    if (!this.driverLocationsLoaded && locationsLoaded) {
      driverLocationsLoaded();
      this.driverLocationsLoaded = true;
    }

    const driversProps = this.getDriversProps();

    return (
      <Fragment>
        {driversProps.map(props => (
          <Driver {...props} />
        ))}
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  driver: { drivers, locations, locationsLoaded }
}) => ({
  drivers,
  locations,
  locationsLoaded
});

export default connect(mapStateToProps)(ScopeDrivers);
