import { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import io from "socket.io-client";
import { apiHost, apiV2Host, locationsApiHost } from "common/config";
import {
  SHIPMENTS_SOCKET_CREATE,
  SHIPMENTS_SOCKET_UPDATE,
  SHIPMENTS_SOCKET_DELETE
} from "state/shipment";
import { MESSAGE_UPDATE } from "state/dialog";
import {
  MEMBER_CONNECTIVITY_CHANGE,
  DRIVER_LOCATION_UPDATE
} from "state/driver";
import { loadDrivers } from "state/driver/driverAction";
import { forceGetShipments } from "state/entities/shipments/action";
import { startOfDay } from "date-fns";
import moment from "moment";

// emit events
const EVENT_EMIT_AUTHENTICATE = "authenticate";

// listener events
const EVENT_SHIPMENTS_CREATE = "shipments_create";
const EVENT_SHIPMENTS_UPDATE = "shipments_update";
const EVENT_SHIPMENTS_DELETE = "shipments_delete";
const EVENT_CHATMESSAGE_CREATE = "chatmessage_create";
const EVENT_CHATMESSAGE_UPDATE = "chatmessage_update";
const EVENT_LOCATION_CREATE = "locations_create";
const EVENT_MEMBER_CONNECTIVITY_CHANGE = "member_connectivity_change";
const EVENT_LOCATION_REGISTER = "join_logisticcompany";

/**
 * Handles socket connection and calls redux events to update the state.
 */
const SocketHandler = ({ token, dispatch, user }) => {

  const [connected, setConnected] = useState(false);
  const [connectedV2, setConnectedV2] = useState(false);
  const [connectedLocation, setConnectedLocation] = useState(false);

  // The “ref” object is a generic container whose current property
  // is mutable and can hold any value, similar to an instance property on a class.
  // See https://reactjs.org/docs/hooks-faq.html#is-there-something-like-instance-variables
  const socket = useRef(null);
  const socketV2 = useRef(null);
  const socketLocation = useRef(null);

  useEffect(() => {
    socket.current = io(apiHost);
    socketV2.current = io(apiHost, {
      path: "/v2/socket.io"
    });
    socketLocation.current = io(locationsApiHost);

    // socketV2.current = io("https://api.test.trackcode.de", {
    //   path: "/v2"
    // });
    // initialize event listeners v1
    socket.current.on("connect", () => {
      console.log("Socket connected");
      setConnected(true);
    });
    socket.current.on("disconnect", () => {
      console.log("Socket disconnected");
      setConnected(false);
    });
    socket.current.on(EVENT_SHIPMENTS_CREATE, ({ content }) => {
      console.log(EVENT_SHIPMENTS_CREATE, content);
      dispatch({
        type: SHIPMENTS_SOCKET_CREATE,
        payload: content
      });
    });
    socket.current.on(EVENT_SHIPMENTS_UPDATE, ({ content }) => {
      console.log(EVENT_SHIPMENTS_UPDATE, content);
      dispatch({
        type: SHIPMENTS_SOCKET_UPDATE,
        payload: content
      });
    });
    socket.current.on(EVENT_SHIPMENTS_DELETE, ({ content }) => {
      console.log(EVENT_SHIPMENTS_DELETE, content);
      dispatch({
        type: SHIPMENTS_SOCKET_DELETE,
        payload: content
      });
    });
    socket.current.on(EVENT_CHATMESSAGE_CREATE, message => {
      console.log(EVENT_CHATMESSAGE_CREATE, message);
      dispatch({
        type: MESSAGE_UPDATE,
        payload: message
      });
    });
    socket.current.on(EVENT_CHATMESSAGE_UPDATE, message => {
      console.log(EVENT_CHATMESSAGE_UPDATE, message);
      dispatch({
        type: MESSAGE_UPDATE,
        payload: message
      });
    });
    socket.current.on(EVENT_LOCATION_CREATE, ({ content }) => {
      console.log(EVENT_LOCATION_CREATE, content);
      dispatch({
        type: DRIVER_LOCATION_UPDATE,
        payload: content
      });
    });
    socket.current.on(EVENT_MEMBER_CONNECTIVITY_CHANGE, ({ content }) => {
      console.log(EVENT_MEMBER_CONNECTIVITY_CHANGE, content);
      dispatch({
        type: MEMBER_CONNECTIVITY_CHANGE,
        payload: content
      });
    });

    // ######### V2 SOCKET
    socketV2.current.on("connect", () => {
      console.log("Socket v2 connected >> join_log", user.logisticcompany_id);
      socketV2.current.emit("join_logisticcompany", user.logisticcompany_id);
      setConnectedV2(true);
    });
    socketV2.current.on("disconnect", () => {
      console.log("Socket v2 disconnected");
      setConnectedV2(false);
    });

    socketV2.current.on(EVENT_MEMBER_CONNECTIVITY_CHANGE, content => {
      console.log(EVENT_MEMBER_CONNECTIVITY_CHANGE, content);
      dispatch({
        type: MEMBER_CONNECTIVITY_CHANGE,
        payload: content
      });

      dispatch(loadDrivers(token, ""));
    });

    socketV2.current.on(EVENT_SHIPMENTS_CREATE, content => {
      console.log(EVENT_SHIPMENTS_CREATE, content);

      const start = startOfDay(new Date());
      const end = moment(new Date()).endOf("day");
      dispatch(loadDrivers(token, ""));
      dispatch(forceGetShipments(start, end));
    });

    // ######### LOCATION SOCKET
    socketLocation.current.on("connect", () => {
      console.log("Socket LOCATION connected");

      socketLocation.current.emit(
        EVENT_LOCATION_REGISTER,
        user.logisticcompany_id
      );

      setConnectedLocation(true);
    });
    socketLocation.current.on("disconnect", () => {
      console.log("Socket Location disconnected");
      setConnectedLocation(false);
    });

    socketLocation.current.on(EVENT_LOCATION_CREATE, content => {
      console.log(EVENT_LOCATION_CREATE, content);
      dispatch({
        type: DRIVER_LOCATION_UPDATE,
        payload: content
      });
    });

    return () => {
      // close socket on end of lifetime
      socket.current.close();
      socketV2.current.close();
      socketLocation.current.close();
    };
    // Guarantee that this effect is only running once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.logisticcompany_id]);

  useEffect(() => {
    if (connected) {
      const logisticCompanyId = user.logisticcompany_id;
      // when connection, token or logisticcompanyId changed, emit authenticate
      console.log("Socket authenticate");
      socket.current.emit(EVENT_EMIT_AUTHENTICATE, {
        token,
        logisticCompanyId
      });
    }

    if (connectedV2) {
      const logisticCompanyId = user.logisticcompany_id;
      console.log("Socket v2 join_logisticcompany");
      // socketV2.current.emit("join_logisticcompany", logisticcompanyId);
    }

    if (connectedLocation) {
      const logisticCompanyId = user.logisticcompany_id;
      console.log("Socket Location authenticate");
      socket.current.emit(EVENT_EMIT_AUTHENTICATE, {
        token,
        logisticCompanyId
      });
    }
  }, [connected, connectedLocation, token, user.logisticcompany_id]);

  return null;
};

const mapStateToProps = ({
  auth: { token, logisticCompanyId: logisticcompanyId, user }
}) => {
  return {
    token
  };
};

// export it for testing
export const SocketHandlerComponent = SocketHandler;

export default connect(mapStateToProps)(SocketHandler);
