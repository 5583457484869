import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Flex, Box } from "reflexbox";
import { differenceInHours } from "date-fns";
import {
  StandardButton,
  StandardSelection,
  StandardModal,
  Icon
} from "@trackcode/ui";
import {
  assignShipmentsToDriver,
  assignShipmentsToTour,
  clearShipmentAssignment
} from "state/entities/shipments/action";
import { getDrivers } from "state/entities/members/selectors";
import LocationService from "../../service/LocationService";
import ShipmentsOnMap from "./ShipmentsOnMap";

// Count on status: pickup, loaded, direct, pending, assigned
const RELEVANT_SHIPMENT_STATUS_FOR_DRIVERS = [4, 5, 14, 22, 23];
const LATEST_LOCATION_HOUR_LIMIT = 8;
const SELECTION_ITEM_UNASSIGNED_VALUE = -1;

const getDriverName = driver => `${driver.firstname} ${driver.lastname}`;

const SelectionItem = ({
  mainLabel,
  subLabel,
  parcelCount = 0,
  idLabel = "",
  hover,
  selected,
  distance,
  subColor = "#777777"
}) => (
  <SelectionItemWrap selected={selected} highlighted={hover}>
    <Box flex={3}>
      <Flex>
        <BoxEllipsis>{mainLabel} </BoxEllipsis>
        <Box>
          &nbsp;{idLabel !== "" ? <IdBadge>{idLabel}</IdBadge> : null}&nbsp;
        </Box>
      </Flex>
      <Flex style={{ color: subColor }}>
        <Box>
          {parcelCount}&nbsp;
          <Icon
            name="parcel"
            style={{ marginBottom: "-2px", fill: subColor }}
          />
        </Box>
        {subLabel ? (
          <>
            <Box>
              <Divider />
            </Box>
            <BoxEllipsis flex={1}>{subLabel}</BoxEllipsis>
          </>
        ) : null}
      </Flex>
    </Box>
    <BoxDistance>
      {distance ? <>{String(distance).replace(".", ",")} km ↦</> : null}
    </BoxDistance>
  </SelectionItemWrap>
);

const selectionColors = {
  highlighted: "#F2F2F2",
  selected: "#e5e5e5"
};

const SelectionItemWrap = styled.div`
  padding: 6px 12px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: ${({ highlighted, selected }) =>
    (highlighted && selectionColors.highlighted) ||
    (selected && selectionColors.selected) ||
    "none"};
`;

const BoxEllipsis = styled(Box)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BoxDistance = styled(Box)`
  width: 85px;
  padding-left: 7px;
  text-align: right;
  color: #777777;
`;

const Divider = styled.div`
  display: inline-block;
  width: 1px;
  height: 10px;
  background: #aaa;
  margin: 0 6px 0 6px;
  align-self: center;
`;

const IdBadge = styled.span`
  border: 1px solid black;
  border-radius: 50%;
  padding: 1px 3px;
  font-size: 11px;
  text-align: center;
`;

/**
 * Get the longitude and latitude for drivers in a location entry map
 * @param {Object} locationDriverIdMap
 * @param {Number|String} driverId
 * @return {{latitude, longitude}|null}
 */
const getLatestLocationForDriver = (locationDriverIdMap, driverId) => {
  const locationEntry = locationDriverIdMap[driverId];
  if (!locationEntry || locationEntry.length === 0) return null;
  const locationDate = new Date(locationEntry[0].datetime);
  if (
    Math.abs(differenceInHours(locationDate, new Date())) >
    LATEST_LOCATION_HOUR_LIMIT
  ) {
    return null;
  }
  const { longitude, latitude } = locationEntry[0];
  return { longitude, latitude };
};

/**
 * Calculate Distance between shipment and driver by lon/lat
 * @param shipment {Object} Enhanced shipment object
 * @param driver {Object} Driver object
 * @return {string}
 */
const calculateDistance = (shipment, driver) => {
  const { latitude, longitude } =
    shipment.isFixedStop || shipment.isPickedUp
      ? shipment.recipient.address
      : shipment.sender.address;
  if (
    latitude &&
    !Number.isNaN(latitude) &&
    longitude &&
    !Number.isNaN(longitude) &&
    driver &&
    driver.latitude &&
    driver.longitude
  ) {
    const distance = LocationService.getDistanceFromLatLonInKm(
      latitude,
      longitude,
      driver.latitude,
      driver.longitude
    );
    return Number.isNaN(distance) ? null : distance;
  }
  return null;
};

/**
 * Render a custom Button for StandardSelection assignment.
 * @param {Boolean} isSelected An option is selected.
 * @return {function} For render prop: `renderButton`
 *
 */
const renderStandardSelectionButton = isSelected => ({ toggle, children }) => {
  const baseColor = isSelected ? "#ffffff" : "#ededed";
  return (
    <StandardButton
      appearance="default"
      onClick={toggle}
      iconAfter={{ name: "angle-down", fill: baseColor }}
      style={{
        color: baseColor,
        borderColor: baseColor,
        background: "none",
        fontWeight: isSelected ? "700" : "normal"
      }}
    >
      {children}
    </StandardButton>
  );
};

/**
 * Adds a "unassigned" item to list of selection items
 * @param {{value: String, content: Object | String}[]} items items for selection component
 * @return {*[]}
 */
const withSelectionItemUnassigned = items => [
  {
    value: SELECTION_ITEM_UNASSIGNED_VALUE,
    content: "Keine Zuweisung",
    // not searchable
    search: " "
  },
  ...items
];

const AssignSelections = ({
  selectedShipmentIds,
  onCancel,
  shipmentMap,
  tours,
  drivers,
  memberIdToTourId,
  driverLocations,
  dispatch
}) => {
  const [assignedTour, setAssignedTourState] = useState(null);
  const [assignedDriver, setAssignedDriverState] = useState(null);
  const [showRecurringModal, setShowRecurringModal] = useState(false);

  /**
   * Set tour assignment.
   * @param {number} value tour id
   */
  const setAssignedTour = value => {
    setAssignedTourState(value);
    // auto select "unassigned"
    if (value === SELECTION_ITEM_UNASSIGNED_VALUE) {
      setAssignedDriverState(value);
    }
    if (value === null) {
      setAssignedDriverState(null);
    }
  };
  /**
   * Set driver assignment.
   * @param {number} value driver/member id
   */
  const setAssignedDriver = value => {
    setAssignedDriverState(value);
    // auto select "unassigned"
    if (value === SELECTION_ITEM_UNASSIGNED_VALUE) {
      setAssignedTourState(value);
    }
    if (value === null) {
      setAssignedTourState(null);
    }
  };

  /**
   * Create assignment.
   * @param {Object} options
   * @param {Boolean} options.allRecurring
   */
  const assign = ({ allRecurring } = {}) => {
    const isClearAction =
      assignedTour === SELECTION_ITEM_UNASSIGNED_VALUE ||
      assignedDriver === SELECTION_ITEM_UNASSIGNED_VALUE;
    // Check if at least one shipment is recurring.
    const someRecurringShipments = selectedShipmentIds.some(
      id => shipmentMap[id] && shipmentMap[id].isRecurring
    );

    if (
      !isClearAction &&
      someRecurringShipments &&
      allRecurring === undefined
    ) {
      // Show modal:
      // Assignment only for this shipment or for all recurring shipments in the future.
      setShowRecurringModal(true);
      return;
    }

    if (isClearAction) {
      // We are not able to clear assignment separately.
      dispatch(clearShipmentAssignment(selectedShipmentIds));
    } else if (assignedTour) {
      // Tour assignment has a higher priority because a tour could be connected with a driver.
      dispatch(
        assignShipmentsToTour(selectedShipmentIds, assignedTour, {
          allRecurring
        })
      );
    } else if (assignedDriver) {
      // Assign a member, if no tour assignment is set.
      dispatch(
        assignShipmentsToDriver(selectedShipmentIds, assignedDriver, {
          allRecurring
        })
      );
    }
    onCancel();
  };

  const selectedShipments = selectedShipmentIds
    .map(uid => shipmentMap[uid])
    .filter(val => val);
  let distanceMap = {};
  if (selectedShipments.length > 0) {
    const shipment = selectedShipments[0];
    distanceMap = Object.keys(drivers).reduce(
      (map, driverId) => ({
        ...map,
        [driverId]: calculateDistance(
          shipment,
          getLatestLocationForDriver(driverLocations, driverId)
        )
      }),
      {}
    );
  }

  /**
   * Get driver from tour.
   * @param {number} tourId
   * @returns {Object} driver
   */
  const getDriverForTour = tourId =>
    tours[tourId] ? drivers[tours[tourId].member_id] || null : null;

  const selectableTourItems = Object.values(tours)
    .filter(tour => (assignedDriver ? tour.member_id === assignedDriver : true))
    .map(tour => {
      const driver = getDriverForTour(tour.id);
      const driverId = driver ? driver.id : 0;
      return {
        value: tour.id,
        content: tour.label,
        // only for sorting
        distance: Number.parseInt(distanceMap[driverId] || "99999")
      };
    })
    .sort((a, b) => a.distance - b.distance)
    // remove distance from object
    .map(({ value, content }) => ({
      value,
      content
    }));

  const selectableDriverItems = Object.values(drivers)
    .filter(driver =>
      assignedTour ? memberIdToTourId[driver.id] === assignedTour : true
    )
    .map(driver => ({
      value: driver.id,
      content: `${getDriverName(driver)} ${driver.ext_id || ""}`,
      // only for sorting
      distance: Number.parseInt(distanceMap[driver.id] || "99999")
    }))
    .sort((a, b) => a.distance - b.distance)
    // remove distance from object
    .map(({ value, content }) => ({
      value,
      content
    }));

  /**
   * Get tour from driver.
   * @param {number} driverId
   */
  const getTourForDriver = driverId => tours[memberIdToTourId[driverId]];

  const jobCountForDrivers = Object.values(shipmentMap)
    .filter(({ status_id: statusId }) =>
      RELEVANT_SHIPMENT_STATUS_FOR_DRIVERS.includes(statusId)
    )
    .reduce((map, shipment) => {
      const driverId =
        shipment.driverId || (shipment.tour && shipment.tour.member_id);
      if (!driverId) {
        return map;
      }
      return {
        ...map,
        [driverId]: map[driverId] + 1 || 1
      };
    }, {});

  // Show tour connected driver polygon on map.
  // NOTE: A better separation of tours and drivers is planned for the future.
  const assignedTourMemberId =
    assignedTour && tours[assignedTour] ? tours[assignedTour].member_id : null;

  return (
    <>
      <ShipmentsOnMap
        selectedShipmentIds={selectedShipmentIds}
        selectedDriverId={assignedDriver || assignedTourMemberId}
      />
      {showRecurringModal && (
        <StandardModal
          size="small"
          heading="Wiederkehrenden Auftrag zuweisen"
          cancelElement={action => (
            <StandardButton onClick={action}>Abbrechen</StandardButton>
          )}
          confirmElement={action => (
            <div style={{ flexDirection: "row" }}>
              <StandardButton
                onClick={() => {
                  action();
                  assign({ allRecurring: true });
                }}
              >
                Alle Ereignisse
              </StandardButton>
              <StandardButton
                appearance="primary"
                onClick={() => {
                  action();
                  assign({ allRecurring: false });
                }}
              >
                Nur dieses Ereignis
              </StandardButton>
            </div>
          )}
          onClose={() => setShowRecurringModal(false)}
        >
          Möchtest du auch alle zukünftigen Ereignisse zuweisen oder nur dieses
          Ereignis
        </StandardModal>
      )}
      <Flex width="100%">
        <Box flex={1.2}>
          <Flex>
            <Box flex={1}>
              <span style={{ color: "#ffffff", paddingRight: "16px" }}>
                {selectedShipmentIds.length}&nbsp;
                {selectedShipmentIds.length > 1 ? "Aufträge" : "Auftrag"}
              </span>
              <StandardSelection
                placeholder="Tour wählen"
                noSuggestionsText="Keine Auswahl möglich"
                allowClear
                menuProps={{
                  minWidth: "325px",
                  maxWidth: "325px"
                }}
                items={withSelectionItemUnassigned(selectableTourItems)}
                onChange={tourItem =>
                  setAssignedTour(tourItem ? tourItem.value : null)
                }
                selectedItem={assignedTour ? { value: assignedTour } : null}
                renderButton={renderStandardSelectionButton(
                  assignedTour !== null
                )}
                renderItem={({
                  item: { value: tourId, content },
                  highlighted,
                  selected
                }) => {
                  const { id: driverId, ...driver } =
                    getDriverForTour(tourId) || {};
                  return tourId < 0 ? (
                    <SelectionItemWrap
                      selected={selected}
                      highlighted={highlighted}
                      style={{ fontStyle: "italic" }}
                    >
                      {content}
                    </SelectionItemWrap>
                  ) : (
                    <SelectionItem
                      mainLabel={tours[tourId].label}
                      subLabel={driverId ? getDriverName(driver) : ""}
                      parcelCount={jobCountForDrivers[driverId]}
                      distance={distanceMap[driverId]}
                      hover={highlighted}
                      selected={selected}
                    />
                  );
                }}
              />
              &nbsp;
              <StandardSelection
                placeholder="Fahrer wählen"
                noSuggestionsText="Keine Auswahl möglich"
                allowClear
                menuProps={{
                  minWidth: "325px",
                  maxWidth: "325px"
                }}
                items={withSelectionItemUnassigned(selectableDriverItems)}
                onChange={driverItem =>
                  setAssignedDriver(driverItem ? driverItem.value : null)
                }
                selectedItem={assignedDriver ? { value: assignedDriver } : null}
                renderButton={renderStandardSelectionButton(
                  assignedDriver !== null
                )}
                renderItem={({
                  item: { value: driverId, content },
                  highlighted,
                  selected
                }) =>
                  driverId < 0 ? (
                    <SelectionItemWrap
                      selected={selected}
                      highlighted={highlighted}
                      style={{ fontStyle: "italic" }}
                    >
                      {content}
                    </SelectionItemWrap>
                  ) : (
                    <SelectionItem
                      mainLabel={getDriverName(drivers[driverId])}
                      subLabel={(getTourForDriver(driverId) || {}).label}
                      parcelCount={jobCountForDrivers[driverId]}
                      idLabel={drivers[driverId].ext_id || ""}
                      distance={distanceMap[driverId]}
                      hover={highlighted}
                      selected={selected}
                    />
                  )
                }
              />
              &nbsp;
              <StandardButton
                disabled={!assignedDriver && !assignedTour}
                onClick={assign}
                style={{
                  backgroundColor: "#ffffff",
                  borderColor: "#ffffff"
                }}
              >
                Speichern
              </StandardButton>
            </Box>
            <Box flex={1} style={{ textAlign: "right" }}>
              <StandardButton
                appearance="subtle"
                style={{
                  padding: "4px",
                  marginLeft: "-5px",
                  color: "#ffffff"
                }}
                onClick={onCancel}
              >
                <Icon name="close" size="md" fill="#ffffff" />
              </StandardButton>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

AssignSelections.propTypes = {
  /** List of selected shipments */
  selectedShipmentIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  /** Is called after saving assignment or when the user aborts. */
  onCancel: PropTypes.func.isRequired,
  shipmentMap: PropTypes.shape({}).isRequired,
  tours: PropTypes.shape({}).isRequired,
  memberIdToTourId: PropTypes.shape({}).isRequired,
  driverLocations: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const {
    driver: { locations: driverLocations },
    entities: {
      tours: { entities: tours, memberIdToTourId },
      shipments: { entities: shipmentMap }
    }
  } = state;
  return {
    shipmentMap: shipmentMap,
    memberIdToTourId,
    tours,
    drivers: getDrivers(state),
    driverLocations
  };
};

export default connect(mapStateToProps)(AssignSelections);
