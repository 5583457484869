import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import * as authAction from "state/auth/authAction";
import {
  AppBar as AppBarUI,
  StandardButton,
  StandardInput,
  Icon,
  StandardDropdown
} from "@trackcode/ui";
import CI from "@trackcode/ci";
import logoTrackCode from "asset/image/trackcode-logo.png";

class AppBar extends Component {
  static propTypes = {
    handleCreateShipment: PropTypes.func.isRequired,
    handleMenuItemClicked: PropTypes.func.isRequired,
    shipmentButtonDisabled: PropTypes.bool.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number,
      firstname: PropTypes.string,
      lastname: PropTypes.string
    }),
    logisticCompanyId: PropTypes.number.isRequired,
    managedLogisticCompanies: PropTypes.shape({
      id: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
      })
    }).isRequired,
    logoutAction: PropTypes.func.isRequired,
    changeLogisticCompanyAction: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired
  };

  static defaultProps = {
    user: null
  };

  state = {
    tracking: "",
    showMenu: false
  };

  componentDidMount() {
    document.addEventListener("click", this.clickListener);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.clickListener);
  }

  /**
   * Change tracking text.
   */
  onChangeTracking = e => {
    this.setState({ tracking: e.target.value });
  };

  setAppBarRef = ref => {
    this.appBarRef = ref;
  };

  clickListener = e => {
    const { showMenu } = this.state;
    const isInside = this.appBarRef ? this.appBarRef.contains(e.target) : false;
    if (showMenu && !isInside) {
      this.setState({ showMenu: false });
    }
  };

  handleTracking = e => {
    const { history } = this.props;
    const { tracking } = this.state;
    if (tracking.length < 2) {
      return;
    }

    // catch onClick and onKeyPress
    if (!e.key || (e.key && e.key === "Enter")) {
      history.push(`/track/${tracking}`);
      setTimeout(() => this.setState({ tracking: "" }), 800);
    }
  };

  handleCreateShipment = () => {
    this.props.handleCreateShipment();
  };

  handleSetting = () => {
    this.props.handleMenuItemClicked("settings");
  };

  handleLogout = () => {
    this.props.logoutAction();
  };

  changeLogisticCompany = id => () => {
    const {
      logisticCompanyId,
      changeLogisticCompanyAction,
      history
    } = this.props;
    // const newId = id === defaultId ? 0 : id;
    const newId = id;

    if (newId !== logisticCompanyId) {
      changeLogisticCompanyAction(newId);
      history.push(`/scheduling`);
    }
    this.setState({ showMenu: false });
  };

  render() {
    const {
      shipmentButtonDisabled,
      user,
      logisticCompanyId,
      managedLogisticCompanies,
      history
    } = this.props;
    const { showMenu, tracking } = this.state;

    const showCurrentLogisticcompany =
      Object.keys(managedLogisticCompanies).length > 1;

    const currLogisticcompanyId =
      logisticCompanyId === 0 ? user.logisticcompany_id : logisticCompanyId;
    const currLogisticcompany =
      managedLogisticCompanies[currLogisticcompanyId] || {};

    const logisticcompanies = Object.values(managedLogisticCompanies);

    return (
      <Header role="navigation">
        <AppBarUI
          innerRef={this.setAppBarRef}
          showMenu={showMenu}
          logo={
            <img
              src={logoTrackCode}
              alt="TrackCode Logo"
              style={{
                height: "32px",
                padding: "5px 10px",
                cursor: showCurrentLogisticcompany ? "auto" : "pointer"
              }}
              onClick={
                showCurrentLogisticcompany
                  ? () => {}
                  : () => {
                      history.push("/scheduling");
                    }
              }
            />
          }
          left={
            showCurrentLogisticcompany && (
              <Fragment>
                <StandardButton
                  // set key for an smooth button switch
                  key="menu-close"
                  appearance="subtle"
                  style={{
                    // use display because of clickListener inside check
                    display: showMenu ? "block" : "none"
                  }}
                  onClick={() => this.setState(() => ({ showMenu: !showMenu }))}
                >
                  <Icon name="close" size="md" />
                </StandardButton>

                <StandardButton
                  // set key for an smooth button switch
                  key="menu-open"
                  appearance="subtle"
                  iconBefore={{
                    name: "menu",
                    width: "21",
                    height: "21"
                  }}
                  textWrap="ellipsis"
                  style={{
                    // use display because of clickListener inside check
                    display: showMenu ? "none" : "block",
                    maxWidth: "100%",
                    fontSize: "15px"
                  }}
                  onClick={() => this.setState(() => ({ showMenu: !showMenu }))}
                >
                  {currLogisticcompany.name}
                </StandardButton>
              </Fragment>
            )
          }
          center={
            <Fragment>
              <StandardInput
                placeholder="Sendungsnummer eingeben"
                spaceAfter={false}
                style={{
                  width: "300px",
                  borderBottomRightRadius: 0,
                  borderTopRightRadius: 0
                }}
                value={tracking}
                onChange={this.onChangeTracking}
                onKeyPress={this.handleTracking}
              />
              <StandardButton
                style={{
                  borderBottomLeftRadius: 0,
                  borderTopLeftRadius: 0,
                  borderLeftWidth: 0
                }}
                onClick={this.handleTracking}
              >
                <Icon name="search" size="md" />
              </StandardButton>
              <StandardButton
                disabled={shipmentButtonDisabled}
                appearance={shipmentButtonDisabled ? "default" : "primary"}
                style={{ whiteSpace: "nowrap" }}
                onClick={this.handleCreateShipment}
              >
                Auftrag hinzufügen
              </StandardButton>
            </Fragment>
          }
          right={
            <StandardButton
              appearance="subtle"
              spaceAfter={false}
              onClick={this.handleSetting}
            >
              <Icon name="setting" size="md" />
            </StandardButton>
          }
          user={
            user && (
              <StandardDropdown
                appearance="subtle"
                justifyContent="flex-start"
                shouldFitContainer
                textWrap="ellipsis"
                iconAfter={{ name: "angle-down" }}
                buttonChildren={`${user.firstname} ${user.lastname}`}
              >
                {Item => [
                  <Item key="logout" onClick={this.handleLogout}>
                    Abmelden
                  </Item>
                ]}
              </StandardDropdown>
            )
          }
          menu={
            <Menu>
              {logisticcompanies.map(({ id, name }) => (
                <MenuItem key={id}>
                  <StandardButton
                    appearance="subtle"
                    shouldFitContainer
                    justifyContent="flex-start"
                    textWrap="ellipsis"
                    style={{
                      color:
                        currLogisticcompanyId === id
                          ? CI.color.brand
                          : CI.color.grayDark,
                      fontWeight:
                        currLogisticcompanyId === id ? "bold" : "normal"
                    }}
                    onClick={this.changeLogisticCompany(id)}
                  >
                    {name}
                  </StandardButton>
                </MenuItem>
              ))}
            </Menu>
          }
        />
      </Header>
    );
  }
}

const mapStateToProps = ({
  auth: { user, logisticCompanyId = 0, managedLogisticCompanies = {} }
}) => ({
  user,
  logisticCompanyId,
  managedLogisticCompanies
});

const mapDispatchToProps = {
  logoutAction: authAction.logout,
  changeLogisticCompanyAction: authAction.changeLogisticCompany
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AppBar)
);

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* more z-index than the old modal  */
  z-index: 2000;
`;

const Menu = styled.div`
  display: flex;
  width: 840px;
  flex-wrap: wrap;
  justify-content: flex-start;
`;
const MenuItem = styled.div`
  min-width: 280px;
  max-width: 280px;
  padding-bottom: 6px;
`;
