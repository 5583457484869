import {
  setApiV2Token,
  setApiV2LogisticCompany,
  clearApiV2Headers
} from "common/apiV2";
import {
  LOGIN,
  LOGOUT,
  LOGISTICCOMPANY_PREPARE_CHANGE,
  LOGISTICCOMPANY_CHANGED,
  MANAGED_LOGISTICCOMPANIES
} from "./index";

const initialState = {
  locale: "de",
  user: null,
  token: null,
  logisticCompanyId: 0,
  logisticCompanyPrepareChange: false,
  managedLogisticCompanies: {},
  // userRole: null,
  // counter is used to trigger component updates based on new props
  loginError: null,
  loginCounter: 0
};

export default function auth(state = initialState, action = {}) {
  const { type, payload, error } = action;

  switch (type) {
    case LOGIN:
      clearApiV2Headers();
      if (error) {
        return {
          ...state,
          user: null,
          token: null,
          loginError: payload,
          loginCounter: state.loginCounter + 1
        };
      }

      setApiV2Token(payload.token);

      return {
        ...state,
        user: {
          ...payload.user,
          name: `${payload.user.firstname} ${payload.user.lastname}`
        },
        token: payload.token,
        loginError: null,
        loginCounter: state.loginCounter + 1
      };

    // we always reset to initial state, even when logout failed
    case LOGOUT:
      clearApiV2Headers();
      return {
        ...initialState,
        loginCounter: state.loginCounter
      };

    case MANAGED_LOGISTICCOMPANIES: {
      const managedLogisticCompanies = (payload || []).reduce(
        (acc, entry) => ({ ...acc, [entry.id]: entry }),
        {}
      );
      return {
        ...state,
        managedLogisticCompanies
      };
    }

    case LOGISTICCOMPANY_PREPARE_CHANGE: {
      const logisticCompanyId = action.logisticCompanyId;

      setApiV2LogisticCompany(logisticCompanyId);

      // change state
      return {
        ...state,
        logisticCompanyId,
        logisticCompanyPrepareChange: true
      };
    }

    case LOGISTICCOMPANY_CHANGED: {
      return {
        ...state,
        logisticCompanyPrepareChange: false
      };
    }

    default:
      return state;
  }
}
