import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import moment from "moment";

import TrackPanel from "../../components/panel/TrackPanel";
import Timeline from "../../components/timeline/Timeline";
import LongAddress from "../../components/address/LongAddress";
import AddressService from "../../service/AddressService";
import ApiService from "../../service/ApiService";

const Container = styled.div`
  flex: 5;
  display: flex;
  min-height: 0px;
`;

const ContainerScroll = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 15px;
  background: #f2f2f2;
`;

class TrackingDetails extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    shipment: PropTypes.shape({}).isRequired
  };

  getPackingUnits() {
    const { shipment } = this.props;

    const { progress } = shipment;

    // status id 4 (fetched) and 5 (loaded)
    const loadingProgress = progress
      .filter(scan => scan.status_id === 4 || scan.status_id === 5)
      .sort((a, b) => a.client_datetime.localeCompare(b.client_datetime))[0];

    if (!loadingProgress) {
      return [];
    }

    const packingUnitsMediaItem = loadingProgress.media.find(
      mediaItem => mediaItem.label === "packing_unit_counter"
    );

    if (!packingUnitsMediaItem) {
      return [];
    }

    const packingUnits = Object.keys(packingUnitsMediaItem.data)
      .sort((a, b) => {
        // Vergleiche die Schlüssel (a und b) als Zahlen in absteigender Reihenfolge
        const numA = parseFloat(a.match(/[\d,]+/)[0].replace(",", "."));
        const numB = parseFloat(b.match(/[\d,]+/)[0].replace(",", "."));
        return numB - numA;
      })
      .map(key => ({
        unit: key,
        counter: packingUnitsMediaItem.data[key]
      }));

    return packingUnits;
  }

  mapProgressForTimeline(progress) {
    const mappedProgress = progress.map(scan => {
      const scanItem = {
        title: scan.translation,
        datetime: moment(scan.client_datetime).isValid()
          ? scan.client_datetime
          : scan.datetime,
        name: scan.name
      };

      if (scan.variation_label) {
        scanItem.variation = scan.variation_label;
      }

      switch (scan.label) {
        case "delivered":
          scanItem.class = "success";
          scanItem.classState = "success";
          scanItem.icon = "check";
          break;
        case "barrier":
        case "damage_documentation":
          scanItem.classState = "warning";
          break;
        default:
          scanItem.classState = "default";
          break;
      }

      scanItem.images = [];
      scanItem.signature = {};

      if (scan.media) {
        scan.media.forEach(media => {
          switch (media.label) {
            case "imagenode":
              (media.data.images || []).forEach(image => {
                scanItem.images.push({
                  full: ApiService.getGridUrl(this.props.token, "", image.uuid),
                  medium: ApiService.getGridUrl(
                    this.props.token,
                    "MEDIUM",
                    image.uuid
                  ),
                  thumb: ApiService.getGridUrl(
                    this.props.token,
                    "THUMB",
                    image.uuid
                  ),
                  name: image.name
                });
              });
              if (media.data.description) {
                scanItem.image_description = media.data.description;
              }
              break;
            case "signature_salutation":
              // TODO translate media.data
              break;
            case "signature":
              scanItem.signature.image = ApiService.getSignatureUrl(
                this.props.token,
                media.id
              );
              break;
            case "signature_name":
              scanItem.signature.name = media.data;
              break;
            case "packing_unit_counter":
              scanItem.packingUnits = media.data;
              break;
            case "total_weight":
              scanItem.weight = media.data;
              break;
            case "packing_unit_counter_description":
              scanItem.packingUnitReason = media.data;
              break;
            case "data_correction":
              scanItem.data_correction = media.data;
              break;
            default:
              break;
          }
        });
      }

      if (Object.keys(scanItem.signature).length === 0) {
        delete scanItem.signature;
      }

      return scanItem;
    });

    return mappedProgress;
  }

  renderWeight() {
    const { shipment } = this.props;

    if (shipment.weight) {
      return <span>Gewicht: {shipment.weight} kg</span>;
    }

    return null;
  }

  render() {
    const { shipment } = this.props;

    if (!shipment) {
      return null;
    }

    let progress = [];
    if (shipment) {
      progress = this.mapProgressForTimeline(shipment.progress);
    }

    const packingUnits = this.getPackingUnits();

    return (
      <Container>
        <ContainerScroll>
          <div className="row">
            {/* TODO: Add Fragments */}
            {shipment.isFixedStop === 0 && (
              <TrackPanel header="Absender">
                <LongAddress
                  address={AddressService.getSendeFromShipment(shipment)}
                  addExtra
                />
              </TrackPanel>
            )}
            {shipment.isFixedStop === 0 && (
              <TrackPanel header="Empfänger">
                <LongAddress
                  address={AddressService.getRecipientFromShipment(shipment)}
                  addExtra
                />
              </TrackPanel>
            )}
            {shipment.isFixedStop === 1 && (
              <TrackPanel header="Stopp">
                <LongAddress
                  address={AddressService.getRecipientFromShipment(shipment)}
                  addExtra
                />
              </TrackPanel>
            )}
            <TrackPanel header="Weitere Informationen">
              {shipment.pieces.map(piece => (
                <React.Fragment>
                  {piece.barcode_label} &nbsp;&nbsp;
                  <span className="label label-success">
                    {piece.translation}
                  </span>
                  <br />
                </React.Fragment>
              ))}
              {packingUnits.map(packingUnit => (
                <React.Fragment>
                  {packingUnit.counter}x {packingUnit.unit}
                  <br />
                </React.Fragment>
              ))}
              {this.renderWeight()}
            </TrackPanel>
          </div>
          <Timeline items={progress} />
        </ContainerScroll>
      </Container>
    );
  }
}

function mapStateToProps({ auth }) {
  return {
    token: auth.token
  };
}

export default connect(mapStateToProps)(TrackingDetails);
