import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";

import Icon from "../element/icon/Icon";
import styleNames from "./timeline.local.css";

const cx = classNames.bind(styleNames);

/* eslint-disable react/no-array-index-key */
const Timeline = ({ items, ...otherProps }) => (
  <div {...otherProps}>
    <ul className={cx("timeline")}>
      {items.map((item, i) => (
        <li key={i} className={cx("timeline-inverted")}>
          <div
            className={cx("timeline-badge", {
              [`${item.class}`]: item.class
            })}
          >
            <Icon name={item.icon} />
          </div>
          <div className={cx("timeline-panel")}>
            <div className={cx("timeline-heading")}>
              <h4 className={cx("timeline-title")}>
                {item.title} von {item.name}
              </h4>
              {item.variation && <h5>{item.variation}</h5>}
              <p>
                <small className={cx("text-muted")}>
                  <Icon name="clock-o" /> {item.datetime}
                </small>
              </p>
            </div>
            <div className={cx("timeline-body")}>
              {item.images.map(image => (
                <a href={image.full} target="_blank" rel="noopener noreferrer">
                  <img src={image.thumb} title={image.name} alt="parcel" />
                </a>
              ))}
              {item.image_description && (
                <div className={cx("timeline-image-description")}>
                  Beschreibung: {item.image_description}
                </div>
              )}
              {item.signature && (
                <React.Fragment>
                  <h4>Signatur</h4>
                  {item.signature.image && (
                    <Fragment>
                      <img src={item.signature.image} alt="signature" />
                      <br />
                    </Fragment>
                  )}
                  Name: {item.signature.name}
                </React.Fragment>
              )}
              {item.packingUnits && Object.keys(item.packingUnits).length > 0 && (
                <React.Fragment>
                  <h4>Lademittel</h4>
                  {Object.entries(item.packingUnits)
                    .sort((a, b) => {
                      // Vergleiche die Schlüssel (a[0] und b[0]) als Zahlen in absteigender Reihenfolge
                      const numA = parseFloat(
                        a[0].match(/[\d,]+/)[0].replace(",", ".")
                      );
                      const numB = parseFloat(
                        b[0].match(/[\d,]+/)[0].replace(",", ".")
                      );
                      return numB - numA;
                    })
                    .map(packingUnit => (
                      <React.Fragment key={packingUnit[0]}>
                        {packingUnit[1]}x {packingUnit[0]}
                        <br />
                      </React.Fragment>
                    ))}
                  {item.packingUnitReason &&
                    `Begründung: ${item.packingUnitReason}`}
                </React.Fragment>
              )}
              {item.data_correction &&
              (item.data_correction.length ||
                item.data_correction.width ||
                item.data_correction.height) ? (
                <React.Fragment>
                  <h4>Maße</h4>
                  L:&nbsp;
                  {item.data_correction.length
                    ? `${item.data_correction.length} cm`
                    : "-"}
                  <br />
                  B:&nbsp;
                  {item.data_correction.width
                    ? `${item.data_correction.width} cm`
                    : "-"}
                  <br />
                  H:&nbsp;
                  {item.data_correction.height
                    ? `${item.data_correction.height} cm`
                    : "-"}
                </React.Fragment>
              ) : null}
              {item.data_correction && item.data_correction.weight && (
                <React.Fragment>
                  <h4>Gewicht</h4>
                  {String(item.data_correction.weight).replace(".", ",")} kg
                </React.Fragment>
              )}
              {item.weight && (
                <React.Fragment>
                  <h4>Gewicht</h4>
                  {item.weight}
                </React.Fragment>
              )}
            </div>
          </div>
        </li>
      ))}
    </ul>
  </div>
);

Timeline.propTypes = {
  items: PropTypes.array
};

export default Timeline;
