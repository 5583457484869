const DEFAULT_CONFIG = {
  API_HOST: "https://api.trackcode.de/",
  API_V2_HOST: "https://api.trackcode.de",
  LOCATIONS_API_HOST: "https://locations.trackcode.de",
  API_KEY: "9d84d89af1659ad76d4b453d97cd503c"
};

/**
 * Load TC API configuration from multiple sources:
 *
 * 1. **PROD**: Read from `index.html`:
 *
 *    In deployed applications, the index.html assigns the configuration to
 *    `window.ENV`.
 *    These values can be set through the env variables of the Docker images.
 *
 * 2. **LOCAL**: Read from `process.env`:
 *
 *    During local development, one can use the `REACT_APP_xxx` env variables
 *    to set the config values.
 *
 * 3. **DEFAULT**: Read from defaults:
 *
 *    This file contains default configuration pointing to `dev` that is used
 *    if no other config was set.
 */
function readConfigValue(key) {
  // Step 1, read from window.ENV
  const windowValue = window.ENV && window.ENV[key];

  if (windowValue && windowValue !== "" && windowValue !== `\${${key}}`) {
    return windowValue;
  }

  // Step 2, read from process.env
  const processValue = process.env[`REACT_APP_${key}`];

  if (processValue && processValue !== "") {
    return processValue;
  }

  // Step 3, return default value
  return DEFAULT_CONFIG[key];
}

export const apiHost = readConfigValue("API_HOST");
export const apiV2Host = readConfigValue("API_V2_HOST");
export const locationsApiHost = readConfigValue("LOCATIONS_API_HOST");
export const apiKey = readConfigValue("API_KEY");
