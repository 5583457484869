import React, {
  Fragment,
  useEffect,
  useState,
  useMemo,
  useLayoutEffect
} from "react";
import styled from "styled-components";
import { Box } from "reflexbox";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CI from "@trackcode/ci";
import {
  Icon,
  StandardDropdown,
  StandardButton,
  MultiSelection
} from "@trackcode/ui";
import { StandardDateInput } from "@trackcode/datepicker";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import { endOfDay, format, startOfDay } from "date-fns";
import { HotKeys } from "react-hotkeys";
import ContentLoader from "react-content-loader";
import Helmet from "react-helmet";
import { forceGetShipments, getShipments } from "state/entities/shipments/action";
import { SHIPMENT_TYPES } from "state/entities/shipments/constants";
import { getTours } from "state/entities/tours/actions";
import { getMembers } from "state/entities/members/actions";
import { getDrivers } from "state/entities/members/selectors";
import { getStatus } from "state/entities/shipmentStatus/actions";
import { getAvailableShipmentStatus } from "state/entities/shipmentStatus/selectors";
import { ActionBar } from "../app/components/ActionBar";
import CreateEditShowShipment from "../../containers/shipment/CreateEditShowShipment";
import {
  getCurrentShipmentCount,
  makeGetEnhancedShipment,
  getSortedAndFilteredShipmentIds
} from "./state/selectors";
import {
  setFilterDate,
  setFilterStatus,
  setFilterTours,
  setFilterDriver,
  hideShipmentEdit,
  showShipmentEdit,
  setScrollTop
} from "./state/action";
import useSelectIds from "./useSelectIds";
import AssignSelections from "./AssignSelections";

const LIST_ITEM_SIZE = 50.5;
const mutedColor = "#777777";
const AlignedIcon = props => (
  // eslint-disable-next-line react/prop-types
  <Icon {...props} style={{ verticalAlign: "middle", ...props.style }} />
);
const ShipmentList = ({
  dispatch,
  shipmentIds = [],
  status,
  tours,
  statusGroups,
  drivers,
  filters,
  shipmentInfo,
  editShipment,
  scrollTop
}) => {
  const shipmentsLoaded = shipmentInfo.loaded;
  const shipmentCount = shipmentIds.length;

  const [
    selectedIdsMap,
    selectById,
    selectNext,
    selectPrevious,
    setMultiSelect,
    setRangeSelect,
    resetSelection,
    isRangeSelect
  ] = useSelectIds(shipmentIds);
  const selectedShipmentIds = useMemo(() => {
    return Object.keys(selectedIdsMap).filter(id => selectedIdsMap[id]);
  }, [selectedIdsMap]);

  // console.log("###### DEBUG >> ShipmentList.js >> selectedIdsMap", selectedIdsMap);
  // console.log("###### DEBUG >> ShipmentList.js >> filters", filters);

  const isSelectionMode = selectedShipmentIds.length > 0;
  const selectDate = date => dispatch(setFilterDate(date));
  const selectStatus = statusIds => dispatch(setFilterStatus(statusIds));
  const selectTours = tourIds => dispatch(setFilterTours(tourIds));
  const selectDrivers = driverIds => dispatch(setFilterDriver(driverIds));
  const statusItems = [
    ...Object.values(statusGroups).map(group => ({
      value: `g${group.id}`,
      content: group.name
    })),
    ...status.map(({ id, label }) => ({
      value: id,
      content: label
    }))
  ];
  const tourItems = Object.values(tours).map(tour => ({
    value: tour.id,
    content: tour.label
  }));

  const driverItems = Object.values(drivers).map(driver => ({
    value: driver.id,
    content: `${driver.firstname} ${driver.lastname}`
  }));

  const hotKeyMap = {
    RESET_SELECTION: "esc",
    SELECT_NEXT: ["down", "shift+down"],
    SELECT_PREVIOUS: ["up", "shift+up"],
    ENABLE_MULTI_SELECT: { sequence: "command", action: "keydown" },
    DISABLE_MULTI_SELECT: { sequence: "command", action: "keyup" },
    ENABLE_RANGE_SELECT: { sequence: "shift", action: "keydown" },
    DISABLE_RANGE_SELECT: { sequence: "shift", action: "keyup" }
  };

  const hotKeyHandlers = {
    RESET_SELECTION: resetSelection,
    SELECT_NEXT: selectNext,
    SELECT_PREVIOUS: selectPrevious,
    ENABLE_MULTI_SELECT: () => setMultiSelect(true),
    DISABLE_MULTI_SELECT: () => setMultiSelect(false),
    ENABLE_RANGE_SELECT: () => setRangeSelect(true),
    DISABLE_RANGE_SELECT: () => setRangeSelect(false)
  };

  useEffect(() => {
    // load data
    const start = startOfDay(filters.date);
    const end = endOfDay(filters.date);
    // dispatch(getShipments(start, end));
    dispatch(forceGetShipments(start, end));
    dispatch(getTours());
    dispatch(getMembers());
    dispatch(getStatus());
  }, [dispatch, filters.date]);

  const listWrapperRef = React.useRef(null);

  useLayoutEffect(() => {
    // restore and save scroll position
    const { current } = listWrapperRef;
    if (current) {
      current.scrollTop = scrollTop;
    }
    return () => {
      if (current) {
        dispatch(setScrollTop(current.scrollTop));
      }
    };
  }, [listWrapperRef, dispatch, scrollTop]);

  return (
    <ListWrapper ref={listWrapperRef}>
      <Helmet title={`Disposition`} />
      {!isSelectionMode && (
        <ActionBar style={{ backgroundColor: "#F2F2F2" }}>
          <Box width={2 / 3}>
            <div style={{ display: "inline-block", verticalAlign: "top" }}>
              <StandardDateInput
                lookAndFeel="button"
                iconBefore={{
                  name: "calendar-outline"
                }}
                style={{ width: 105, color: "#000" }}
                placeholder={format(filters.date, "DD.MM.YYYY")}
                selected={filters.date}
                onChange={selectDate}
              />
            </div>
            <MultiSelection
              placeholder="Touren: Alle"
              items={tourItems}
              allowClear
              onChange={selectedTourItems =>
                selectTours(selectedTourItems.map(item => item.value))
              }
              selectedItems={tourItems.filter(item =>
                filters.tourIds.includes(item.value)
              )}
              buttonTextWrap="ellipsis"
              buttonStyle={{ maxWidth: "250px" }}
            />
            <MultiSelection
              placeholder="Fahrer: Alle"
              items={driverItems}
              allowClear
              onChange={selectedDriverItems =>
                selectDrivers(selectedDriverItems.map(item => item.value))
              }
              selectedItems={driverItems.filter(item =>
                filters.driverIds.includes(item.value)
              )}
              buttonTextWrap="ellipsis"
              buttonStyle={{ maxWidth: "250px" }}
            />
            <MultiSelection
              placeholder="Status: Alle"
              items={statusItems}
              allowClear
              onChange={selectedStatusItems =>
                selectStatus(selectedStatusItems.map(item => item.value))
              }
              selectedItems={statusItems.filter(item =>
                filters.statusIds.includes(item.value)
              )}
              buttonTextWrap="ellipsis"
              buttonStyle={{ maxWidth: "250px" }}
            />
          </Box>
          <Box width={1 / 3} style={{ textAlign: "right" }}>
            {shipmentCount} von {shipmentInfo.countForDate}
          </Box>
        </ActionBar>
      )}
      {editShipment.active && (
        <CreateEditShowShipment
          showModal={editShipment.active}
          shipmentId={editShipment.shipmentId}
          handleClose={() => dispatch(hideShipmentEdit())}
          handleSubmit={() => dispatch(hideShipmentEdit())}
          editMode={false}
          willBeEdited
        />
      )}
      {isSelectionMode && (
        <ActionBar style={{ backgroundColor: "#98C566" }}>
          <AssignSelections
            selectedShipmentIds={selectedShipmentIds}
            onCancel={() => resetSelection()}
          />
        </ActionBar>
      )}

      {!shipmentsLoaded &&
        [
          ...Array(
            listWrapperRef.current
              ? Math.floor(
                  listWrapperRef.current.clientHeight / LIST_ITEM_SIZE - 1
                )
              : 10
          ).keys()
        ].map(num => <PlaceholderItem height={LIST_ITEM_SIZE} key={num} />)}
      {shipmentCount > 0 && (
        <HotKeys
          keyMap={hotKeyMap}
          handlers={hotKeyHandlers}
          component={FocusDiv}
        >
          {shipmentIds.map(shipmentId => (
            <div
              key={shipmentId}
              style={{
                display: "flex",
                borderBottom: `1.5px dotted ${CI.color.gray}`,
                userSelect: isRangeSelect ? "none" : "auto"
              }}
            >
              <ShipmentItem
                shipmentId={shipmentId}
                isSelected={selectedIdsMap[shipmentId] || false}
                select={selectById}
                setMultiSelect={setMultiSelect}
              />
            </div>
          ))}
        </HotKeys>
      )}
      {shipmentsLoaded && shipmentCount === 0 && (
        <NoParcelsNote>
          <h3>
            <AlignedIcon
              name="parcel"
              width={20}
              height={20}
              style={{
                verticalAlign: "middle",
                marginTop: "-4px",
                marginRight: "5px"
              }}
            />
            Keine Sendungen
          </h3>

          <p>
            Für den gewählten Zeitraum und die spezifizierten Filter sind keine
            Sendungen vorhanden.
          </p>
        </NoParcelsNote>
      )}
    </ListWrapper>
  );
};

ShipmentList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  shipments: PropTypes.arrayOf(PropTypes.string).isRequired,
  status: PropTypes.shape({}).isRequired,
  tours: PropTypes.shape({}).isRequired,
  statusGroups: PropTypes.shape({}).isRequired,
  drivers: PropTypes.shape({}).isRequired,
  filters: PropTypes.shape({}).isRequired,
  shipmentInfo: PropTypes.shape({}).isRequired,
  editShipment: PropTypes.shape({}).isRequired,
  scrollTop: PropTypes.number.isRequired
};

const getDriverName = driver => {
  if (!driver) {
    return "";
  }
  return `${driver.firstname} ${driver.lastname}`;
};

const getDriverNameFromTour = tour => {
  if (tour && tour.member_id) {
    return `${tour.firstname} ${tour.lastname}`;
  }
  return "";
};

const getNameForShipmentType = type => {
  const shipmentTypeMap = {
    DELIVERY: "Zustellung",
    PICKUP: "Abholung",
    DIRECT: "Direktfahrt",
    STOP: "Stopp"
  };
  return shipmentTypeMap[type] || "";
};

const ShowAddress = ({ address, isDestination }) => {
  const { street, zip, city, state, country } = address;
  const inlineAddress = [
    street,
    [zip, city].filter(Boolean).join(" "),
    state,
    country
  ]
    .filter(Boolean)
    .join(", ");
  return (
    <Fragment>
      {isDestination && (
        <AlignedIcon name="map-marker" size="sm" fill={mutedColor} />
      )}
      {!isDestination && (
        <AlignedIcon
          name="subdirectory-arrow-right"
          size="sm"
          fill={mutedColor}
        />
      )}
      <AlignedText>{inlineAddress}</AlignedText>
    </Fragment>
  );
};

ShowAddress.propTypes = {
  address: PropTypes.shape({
    street: PropTypes.string,
    zip: PropTypes.number,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string
  }).isRequired,
  isDestination: PropTypes.bool.isRequired
};

const ShowTime = ({ start, end }) => {
  if (!start && !end) {
    return "";
  }
  const startString = start ? format(start, "HH:mm") : "";
  const endString = end ? format(end, "HH:mm") : "";
  let timeString = "";
  if (end && start) {
    timeString = ` ${startString} - ${endString} `;
  } else if (start && !end) {
    timeString = ` ab ${startString} `;
  } else {
    timeString = ` bis ${endString} `;
  }
  return (
    <Fragment>
      <AlignedText>{timeString}</AlignedText>
    </Fragment>
  );
};

ShowTime.propTypes = {
  start: PropTypes.instanceOf(Date),
  end: PropTypes.instanceOf(Date)
};

const ShowAvis = ({ avis }) => {
  if (!avis) {
    return "";
  }
  return (
    <Fragment>
      <AlignedIcon
        name="message-text"
        size="sm"
        data-tip={avis}
        fill={mutedColor}
      />
      <ReactTooltip place="top" type="dark" effect="solid" />
    </Fragment>
  );
};

ShowAvis.propTypes = {
  avis: PropTypes.string.isRequired
};

const MapStateToPropsShipment = () => {
  // see https://github.com/reduxjs/redux/blob/master/docs/recipes/ComputingDerivedData.md#sharing-selectors-across-multiple-components
  const selector = makeGetEnhancedShipment();
  return (state, props) => ({ shipment: selector(state, props) });
};

const ShipmentItem = connect(MapStateToPropsShipment)(
  React.memo(
    ({ isSelected, select, setMultiSelect, isRangeSelect, shipment }) => {
      const [isHovered, setIsHovered] = useState(false);
      const [isDropdownOpen, setIsDropdownOpen] = useState(false);
      const isDirectShipment = shipment.type === SHIPMENT_TYPES.DIRECT;
      const isPickUpShipment = shipment.type === SHIPMENT_TYPES.PICKUP;
      return (
        <ShipmentItemContent
          selected={isSelected}
          onClick={() => {
            select(shipment.uid);
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Column style={{ marginLeft: "10px" }}>
            <input
              type="checkbox"
              checked={isSelected}
              readOnly
              onClick={() => !isRangeSelect && setMultiSelect(true)}
              onBlur={() => setMultiSelect(false)}
            />
          </Column>
          <Column flex={1}>
            <Ellipsis>
              {shipment.isRecurring && (
                <AlignedIcon name="autorenew" size="sm" />
              )}
              {getNameForShipmentType(shipment.type)}
            </Ellipsis>
          </Column>
          <Column flex={4}>
            <Ellipsis>
              {isDirectShipment && (
                <ShowAddress address={shipment.sender.address} isDestination />
              )}
            </Ellipsis>
            {isPickUpShipment ? (
              <Ellipsis>
                <ShowAddress address={shipment.sender.address} isDestination />
              </Ellipsis>
            ) : (
              <Ellipsis muted={isDirectShipment}>
                <ShowAddress
                  address={shipment.recipient.address}
                  isDestination={!isDirectShipment}
                />
              </Ellipsis>
            )}
          </Column>
          <Column flex={2}>
            {isDirectShipment && (
              <Ellipsis muted>
                <ShowTime
                  start={shipment.sender.time.from}
                  end={shipment.sender.time.till}
                />
                <ShowAvis avis={shipment.sender.avis} />
              </Ellipsis>
            )}
            <Ellipsis muted>
              <ShowTime
                start={shipment.recipient.time.from}
                end={shipment.recipient.time.till}
              />
              <ShowAvis avis={shipment.recipient.avis} />
            </Ellipsis>
          </Column>
          <Column flex={2}>
            <Ellipsis>{shipment.tour && shipment.tour.label}</Ellipsis>
            <Ellipsis muted={shipment.tour}>
              {shipment.driver
                ? getDriverName(shipment.driver)
                : getDriverNameFromTour(shipment.tour)}
            </Ellipsis>
          </Column>
          <Column flex={2}>
            {shipment.statusLabel && (
              <StatusBadge>{shipment.statusLabel}</StatusBadge>
            )}
          </Column>
          {isHovered || isDropdownOpen ? (
            <ShipmentItemActions
              shipment={shipment}
              isSelected={isSelected}
              isRangeSelect={isRangeSelect}
              onDropdownToggle={setIsDropdownOpen}
            />
          ) : null}
        </ShipmentItemContent>
      );
    }
  )
);

ShipmentItem.propTypes = {
  shipmentId: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  select: PropTypes.func.isRequired
};

const ListWrapper = styled.div`
  flex: 1;
  overflow-y: scroll;
  min-width: 650px;
`;

const ShipmentItemContent = styled.div`
  position: relative;
  background: ${({ selected }) => (selected ? "#ffffcc" : "none")};
  display: flex;
  flex: 1;
  cursor: pointer;
  min-height: 49px;
  cursor: pointer;
`;

const Column = styled.div`
  flex: ${({ flex }) => flex};
  flex-direction: column;
  margin: 4px 4px 0 4px;
  min-width: 0;
`;

const Ellipsis = styled.div`
  overflow: hidden;
  white-space: no-wrap;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  color: ${({ muted }) => (muted ? mutedColor : "inherit")};
  &::after {
    content: " ";
    white-space: ${({ children }) => (children ? "pre" : "normal")};
  }
`;

const AlignedText = styled.span`
  vertical-align: middle;
  line-height: 1;
`;

// see https://github.com/greena13/react-hotkeys#blue-border-appears-around-children-of-hotkeys
// const FocusDiv = styled.div`
//   &:focus {
//     outline: 0;
//   }
// `;

const StyledDiv = styled.div`
  &:focus {
    outline: 0;
  }
`;

const FocusDiv = React.forwardRef((props, ref) => (
  <StyledDiv ref={ref} {...props} />
));

const StatusBadge = styled.span`
  background-color: #777777;
  padding: 3px 6px;
  font-size: 11px;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.25em;
  text-transform: uppercase;
`;

const NoParcelsNote = styled.div`
  width: 80%;
  margin: 30px auto;
  padding: 15px;
  background: #f2f2f2;
  border-radius: 2px;
  border: 1px solid #f2f2f2;
`;

const ShipmentItemActions = connect()(
  ({ shipment, isSelected, onDropdownToggle, dispatch }) => (
    <ShipmentItemActionsWrapper onClick={e => e.stopPropagation()}>
      <StandardDropdown
        placement="bottomRight"
        renderButton={({ toggle, isOpen }) => {
          onDropdownToggle(isOpen);
          return (
            <StandardButtonAction
              spaceAfter={false}
              isOpen={isOpen}
              isSelected={isSelected}
              onClick={() => toggle()}
            >
              <AlignedIcon name="more-vertical" size="md" />
            </StandardButtonAction>
          );
        }}
      >
        {Item => [
          <Item
            onClick={() => dispatch(showShipmentEdit(shipment.uid))}
            key="showDetails"
          >
            Auftragsdetails anzeigen
          </Item>,

          <LinkWrapper
            to={`/track/${shipment.group_reference}/progress`}
            key="addProgress"
          >
            <Item>Auftragsstatus hinzufügen</Item>
          </LinkWrapper>,
          <LinkWrapper
            to={`/track/${shipment.group_reference}`}
            key="trackShipment"
          >
            <Item>Sendung verfolgen</Item>
          </LinkWrapper>
        ]}
      </StandardDropdown>
    </ShipmentItemActionsWrapper>
  )
);

const ShipmentItemActionsWrapper = styled.div`
  position: absolute;
  right: 4px;
  top: 2px;
`;

const StandardButtonAction = styled(StandardButton)`
  min-height: 26px;
  background: ${({ isOpen }) => (isOpen ? CI.color.grayLight : "#fff")};
  border: 0;
  box-shadow: ${({ isOpen, isSelected }) =>
    isOpen || isSelected ? "inset 0px 0px 2px 0px rgba(0,0,0,0.2)" : "none"};
  padding: 0px 6px 0px 6px;
`;

const LinkWrapper = props => (
  <Link style={{ color: "#333", textDecoration: "none" }} {...props} />
);
const mapStateToProps = state => {
  const {
    entities: {
      shipments: { loaded },
      tours: { entities: tours },
      shipmentStatus: { groups: statusGroups }
    },
    shipmentV2: {
      selectedDate,
      selectedStatusIds,
      selectedTourIds,
      selectedDriverIds,
      editShipment,
      scrollTop
    }
  } = state;
  return {
    shipmentIds: getSortedAndFilteredShipmentIds(state),
    status: getAvailableShipmentStatus(state),
    tours,
    statusGroups,
    drivers: getDrivers(state),
    shipmentInfo: {
      loaded,
      countForDate: getCurrentShipmentCount(state)
    },
    filters: {
      date: selectedDate,
      statusIds: selectedStatusIds,
      tourIds: selectedTourIds,
      driverIds: selectedDriverIds
    },
    scrollTop,
    editShipment
  };
};

const PlaceholderItem = ({ height, ...props }) => {
  const random = useMemo(() => Math.random() * (1 - 0.9) + 0.9, []);
  return (
    <div
      style={{
        width: "100%",
        height,
        borderBottom: `1.5px dotted ${CI.color.gray}`
      }}
    >
      <ContentLoader
        style={{ width: "100%", height }}
        speed={2}
        primaryColor="rgb(238, 238, 238)"
        secondaryColor="rgb(221, 221, 221)"
        {...props}
      >
        <rect x="7" y="20" width="34" height="30" />
        <rect x="50" y="20" width={131 * random} height="30" />
        <rect x="50" y="60" width={90 * random} height="20" />
        {random > 0.92 && <rect x="190" y="20" width="36" height="30" />}
        {random > 0.95 && <rect x="260" y="20" width="30" height="30" />}
        <rect x="330" y="20" width={35 * random} height="30" />
      </ContentLoader>
    </div>
  );
};

PlaceholderItem.propTypes = {
  height: PropTypes.number.isRequired
};

export default connect(mapStateToProps)(ShipmentList);
