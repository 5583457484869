import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import UserService from "../../service/UserService";
import Icon from "../element/icon/Icon";
import Badge from "../element/badge/Badge";

import classNames from "../../../node_modules/classnames/bind";
import styleNames from "./chatList.local.css";

const cx = classNames.bind(styleNames);

class ChatList extends Component {
  static propTypes = {
    drivers: PropTypes.array.isRequired,
    messages: PropTypes.array,
    handleDriverClicked: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.unreadMessages = {};
    this.toggle = [];
  }

  componentWillReceiveProps(nextProps) {
    const { messages } = nextProps;
    if (messages && messages.length && !messages[0].id) {
      if (!this.unreadMessages[messages[0].from_member_id]) {
        this.unreadMessages[messages[0].from_member_id] = 1;
      } else {
        this.unreadMessages[messages[0].from_member_id] += 1;
      }
    }
  }

  onDriverClicked = e => {
    e.preventDefault();
    const { drivers, handleDriverClicked } = this.props;
    for (let i = 0; i < drivers.length; i += 1) {
      if (Number(drivers[i].id) === Number(e.currentTarget.dataset.id)) {
        this.unreadMessages[drivers[i].id] = 0;
        if (!this.toggle.includes(drivers[i].id)) {
          this.toggle.push(drivers[i].id);
          handleDriverClicked(drivers[i], false);
        } else {
          this.toggle.splice(this.toggle.indexOf(drivers[i].id), 1);
          handleDriverClicked(drivers[i], true);
        }
        return;
      }
    }
  };

  render() {
    const { drivers } = this.props;

    return (
      <ul className={cx("chat-item-list")}>
        {drivers.map(driver => (
          <li key={`driver-${driver.id}`} className={cx("chat-item")}>
            {/* <Link
              to={`dialog-${driver.id}`}
              onClick={this.onDriverClicked}
              data-id={driver.id}
            > */}
            <div className={cx("chat-icon")}>
              {driver.online === 1 && <Icon name="checkbox-blank-circle" />}
            </div>
            <div className={cx("message-badge")}>
              {this.unreadMessages[driver.id] > 0 && (
                <Badge text={this.unreadMessages[driver.id]} />
              )}
            </div>
            <div className={cx("chat-user")}>{UserService.getName(driver)}</div>
            {/* </Link> */}
          </li>
        ))}
      </ul>
    );
  }
}

const mapStateToProps = ({ auth: { token }, dialog: { messages } }) => ({
  token,
  messages
});

export default connect(mapStateToProps)(ChatList);
