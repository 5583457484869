import React from "react";
import PropTypes from "prop-types";
import RBMenuItem from "react-bootstrap/lib/MenuItem";

const MenuItem = ({ children, ...otherProps }) => (
  <RBMenuItem {...otherProps}>{children}</RBMenuItem>
);

MenuItem.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  divider: PropTypes.bool,
  header: PropTypes.bool,
  children: PropTypes.any
};

export default MenuItem;
