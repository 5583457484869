import { isValid, setHours, setMinutes, setSeconds, format } from "date-fns";
import {
  SHIPMENT_ASSIGN_DRIVER_ERROR,
  SHIPMENT_ASSIGN_DRIVER_SUCCESS,
  SHIPMENT_ASSIGN_TOUR_ERROR,
  SHIPMENT_ASSIGN_TOUR_SUCCESS,
  SHIPMENT_CLEAR_ASSIGNMENT_ERROR,
  SHIPMENT_CLEAR_ASSIGNMENT_SUCCESS,
  SHIPMENT_DRIVER_JOBS_MAPPING,
  SHIPMENT_TYPES,
  SHIPMENTS_GET_ERROR,
  SHIPMENTS_GET_RECEIVE,
  SHIPMENTS_GET_REQUEST,
  SHIPMENTS_FROM_JOBS
} from "./constants";

import { TOURS_GET_RECEIVE } from "../tours/constants";

import {
  SHIPMENTS_SOCKET_CREATE,
  SHIPMENTS_SOCKET_DELETE,
  SHIPMENTS_SOCKET_UPDATE
} from "state/shipment";

const initialState = {
  entities: {},
  driverJobsMapping: {
    // [driverId]: [1, 2, ... shipment id's]
  },
  loaded: false,
  errors: {},
  loadedDates: {}
};

/**
 * Extract the address from a shipment for a given prefix, e.g. sender or recipient
 * @param prefix String
 * @param shipment Object
 * @return {{zip: String, city: String, street: String, state: String, country: String, latitude: Number, longitude: Number}}
 */
export const getAddress = (prefix, shipment) => ({
  additional: shipment[`${prefix}_address_additional`],
  street: shipment[`${prefix}_address_street`],
  zip: parseInt(shipment[`${prefix}_address_zipcode`], 10),
  city: shipment[`${prefix}_address_city`],
  state: shipment[`${prefix}_address_state`],
  country: shipment[`${prefix}_address_country`],
  latitude: parseFloat(shipment[`${prefix}_address_latitude`]),
  longitude: parseFloat(shipment[`${prefix}_address_longitude`])
});

/**
 * Extract the avis from a shipment for a given prefix, e.g. sender or recipient
 * @param prefix String
 * @param shipment Object
 * @return {String}
 */
export const getAvis = (prefix, shipment) => shipment[`${prefix}_avis`] || "";

/**
 * Extract the company from a shipment for a given prefix, e.g. sender or recipient
 * @param prefix String
 * @param shipment Object
 * @return {String}
 */
export const getCompany = (prefix, shipment) =>
  shipment[`${prefix}_company`] || "";

/**
 * Extract the name from a shipment for a given prefix, e.g. sender or recipient
 * @param prefix String
 * @param shipment Object
 * @return {String}
 */
const getName = (prefix, shipment) => shipment[`${prefix}_name`] || "";

/**
 * Parse a shipment time to a datetime if possible. Note that the day gets ignored
 * @param value
 * @return {null|Date}
 */
export const getTime = value => {
  if (value === undefined) {
    return null;
  }

  const [, time] = value.split(" ");
  if (time) {
    const [hours, minutes] = time.split(":").map(val => parseInt(val, 10));
    if (hours === 0 && minutes === 0) {
      return null;
    }
    const date = new Date();
    return setHours(setMinutes(setSeconds(date, 0), minutes), hours);
  }
  return null;
};

/**
 * Parse a shipment time to a valid day if possible
 * @param value
 * @return {null|Date}
 */
const getDate = value => {
  if (!value) {
    return null;
  }
  const d = new Date(value);
  if (!isValid(d)) {
    return null;
  }
  return d;
};

const isObject = obj => obj != null && obj.constructor.name === "Object";

/**
 * @param tourId
 * @param tourMap Object tourId -> tour
 * @return {null|Number}
 */
const getDriverIdFromTour = (tourId, tourMap = {}) => {
  const tour = tourMap[tourId];
  if (!tour) {
    return null;
  }
  return tour.member_id;
};

/**
 * A helper function for reduce. Transform is to map (shipment) by using the uid
 * property
 * @param map Object
 * @param shipment Object An object which has a `uid` property
 */
const reduceToIdMap = (map, shipment) => ({
  ...map,
  [shipment.uid]: shipment
});

/**
 * Make cleaner and more consistent shipment objects before putting them into the store
 * @param shipment
 * @param tours
 * @return {object}
 */
export const cleanAndPrepareShipment = (shipment, tours) => {
  const isPickedUp = shipment.isPickedUp === 1;
  const isDirect = shipment.isDirect === 1;
  const isFixedStop = shipment.isFixedStop === 1;
  const isRecurrenceTemplate = shipment.is_recurrence_template === 1;
  const isRecurring = shipment.is_recurring;
  const isAssigned =
    shipment.responsible_member_id > 0 || shipment.responsible_tour_id > 0;
  const tourId =
    shipment.responsible_tour_id || shipment.last_responsible_tour_id;

  let type = "";
  if (isFixedStop) {
    type = SHIPMENT_TYPES.STOP;
  } else if (isDirect) {
    type = SHIPMENT_TYPES.DIRECT;
  } else if (isPickedUp) {
    type = SHIPMENT_TYPES.DELIVERY;
  } else {
    type = SHIPMENT_TYPES.PICKUP;
  }
  const sender = {
    name: getName("sender", shipment),
    address: getAddress("sender", shipment),
    avis: getAvis("sender", shipment),
    company: getCompany("sender", shipment),
    time: {
      from: getTime(shipment.pickup_appointment_from),
      till: getTime(shipment.pickup_appointment_till)
    }
  };
  const recipient = {
    name: getName("recipient", shipment),
    address: getAddress("recipient", shipment),
    avis: getAvis("recipient", shipment),
    company: getCompany("recipient", shipment),
    time: {
      from: getTime(shipment.delivery_appointment_from),
      till: getTime(shipment.delivery_appointment_till)
    }
  };

  // See TC-886
  const possibleDueDate = isPickedUp
    ? getDate(shipment.delivery_appointment_from)
    : getDate(shipment.pickup_appointment_from);
  const dueDate = possibleDueDate || getDate(shipment.creation_datetime);

  const driverId =
    shipment.responsible_member_id ||
    shipment.last_responsible_member_id ||
    getDriverIdFromTour(tourId, tours);

  const uid = isRecurrenceTemplate
    ? `${shipment.id}-${format(dueDate, "YYYY-MM-DD")}`
    : `${shipment.id}`;

  return {
    ...shipment,
    isDirect,
    isFixedStop,
    isPickedUp,
    isRecurring,
    isAssigned,
    isRecurrenceTemplate,
    type,
    sender,
    recipient,
    dueDate,
    driverId,
    tourId,
    uid
  };
};

export default function shipmentReducer(state = initialState, action = {}) {
  // tours comes from the modified rootReducer in entities!
  const { type, payload, error, date, tours } = action;

  switch (type) {
    case SHIPMENT_ASSIGN_DRIVER_SUCCESS: {
      const { shipmentIds, driverId } = payload;
      const updatedShipmentsMap = shipmentIds
        .map(uid => {
          const shipment = state.entities[uid];
          return {
            ...shipment,
            driverId
          };
        })
        .reduce(reduceToIdMap, {});
      return {
        ...state,
        entities: {
          ...state.entities,
          ...updatedShipmentsMap
        }
      };
    }

    case SHIPMENT_ASSIGN_TOUR_SUCCESS: {
      const { shipmentIds, tourId } = payload;
      const updatedShipmentsMap = shipmentIds
        .map(uid => {
          const shipment = state.entities[uid];
          return {
            ...shipment,
            tourId
          };
        })
        .reduce(reduceToIdMap, {});
      return {
        ...state,
        entities: {
          ...state.entities,
          ...updatedShipmentsMap
        }
      };
    }

    case SHIPMENT_CLEAR_ASSIGNMENT_SUCCESS: {
      const { shipmentIds } = payload;
      const updatedShipmentsMap = shipmentIds
        .map(uid => {
          const shipment = state.entities[uid];
          return {
            ...shipment,
            tourId: 0,
            driverId: 0
          };
        })
        .reduce(reduceToIdMap, {});
      return {
        ...state,
        entities: {
          ...state.entities,
          ...updatedShipmentsMap
        }
      };
    }

    case SHIPMENT_CLEAR_ASSIGNMENT_ERROR:
    case SHIPMENT_ASSIGN_TOUR_ERROR:
    case SHIPMENT_ASSIGN_DRIVER_ERROR:
    case SHIPMENTS_GET_ERROR: {
      return {
        ...state,
        loaded: false,
        errors: error
      };
    }

    case SHIPMENTS_GET_REQUEST: {
      return {
        ...state,
        loaded: false
      };
    }

    case SHIPMENTS_GET_RECEIVE: {
      const shipmentList = payload.shipments || [];
      const shipmentMap = shipmentList.reduce((map, rawShipment) => {
        const shipment = cleanAndPrepareShipment(rawShipment, tours);
        return {
          ...map,
          [shipment.uid]: shipment
        };
      }, {});

      return {
        ...state,
        entities: {
          ...state.entities,
          ...shipmentMap
        },
        selectedDate: payload.dateRange,
        loaded: true,
        loadedDates: {
          ...state.loadedDates,
          [date.toISOString()]: +new Date()
        },
        errors: {}
      };
    }

    case SHIPMENT_DRIVER_JOBS_MAPPING: {
      const { driverId, shipmentIds } = action;
      return {
        ...state,
        driverJobsMapping: {
          ...state.driverJobsMapping,
          [driverId]: shipmentIds
        }
      };
    }

    case SHIPMENTS_FROM_JOBS:
    case SHIPMENTS_SOCKET_UPDATE:
    case SHIPMENTS_SOCKET_CREATE: {
      // Handle incoming Array or Object:
      const shipmentList = Array.isArray(payload) ? payload : [payload];
      const shipmentMap = shipmentList
        .filter(Boolean)
        .reduce((map, rawShipment) => {
          const shipment = cleanAndPrepareShipment(rawShipment, tours);
          return {
            ...map,
            [shipment.uid]: shipment
          };
        }, {});
      return {
        ...state,
        entities: {
          ...state.entities,
          ...shipmentMap
        }
      };
    }

    case SHIPMENTS_SOCKET_DELETE: {
      if (!isObject(payload)) {
        console.warn("SHIPMENTS_SOCKET_DELETE's payload was not an object");
        return state;
      }
      const removeId = payload.id;
      const shipments = Object.values(state.entities)
        .filter(shipment => shipment.id !== removeId)
        .reduce(reduceToIdMap, {});
      return {
        ...state,
        entities: shipments
      };
    }

    case TOURS_GET_RECEIVE: {
      // payload is here an array of tours
      const tourMap = payload.reduce(
        (map, tour) => ({
          ...map,
          [tour.id]: tour
        }),
        {}
      );
      const shipments = Object.values(state.entities)
        .map(shipment => {
          if (!shipment.driverId) {
            return {
              ...shipment,
              driverId: getDriverIdFromTour(shipment.tourId, tourMap)
            };
          }
          return shipment;
        })
        .reduce(reduceToIdMap, {});
      return {
        ...state,
        entities: shipments
      };
    }

    default: {
      return state;
    }
  }
}
