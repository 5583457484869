import {
  SHIPMENTS_GET_RECEIVE,
  SHIPMENTS_GET_REQUEST,
  SHIPMENTS_GET_ERROR,
  SHIPMENTS_GET_CACHED,
  SHIPMENT_ASSIGN_DRIVER_SUCCESS,
  SHIPMENT_ASSIGN_DRIVER_REQUEST,
  SHIPMENT_ASSIGN_DRIVER_ERROR,
  SHIPMENT_ASSIGN_TOUR_SUCCESS,
  SHIPMENT_ASSIGN_TOUR_REQUEST,
  SHIPMENT_ASSIGN_TOUR_ERROR,
  SHIPMENT_DRIVER_JOBS_MAPPING,
  SHIPMENT_CLEAR_ASSIGNMENT_REQUEST,
  SHIPMENT_CLEAR_ASSIGNMENT_SUCCESS,
  SHIPMENT_CLEAR_ASSIGNMENT_ERROR
} from "./constants";
import { SHIPMENTS_FROM_JOBS } from "state/entities/shipments/constants";
import ApiService from "../../../service/ApiService";

/**
 * Get all shipments for a start and end date
 * @param {Date} start
 * @param {Date} end
 * @return {Function}
 */
export const getShipments = (start, end) => async (dispatch, getState) => {
  const date = start;
  const onSuccess = payload => {
    dispatch({ type: SHIPMENTS_GET_RECEIVE, payload, date });
    return payload;
  };

  // Use cached shipments if that date is already loaded
  const { entities, loadedDates } = getState().entities.shipments;
  if (loadedDates[date.toISOString()]) {
    dispatch({ type: SHIPMENTS_GET_CACHED });
    return entities;
  }

  dispatch({ type: SHIPMENTS_GET_REQUEST });
  try {
    const {
      auth: { token }
    } = getState();
    const shipments = await ApiService.getShipments(token, {
      filter: "appointment",
      start,
      end
    });
    return onSuccess(shipments);
  } catch (error) {
    dispatch({ type: SHIPMENTS_GET_ERROR, error });
    return null;
  }
};

/**
 * Force to get all shipments for a start and end date because we need to reload it.
 * @param {Date} start
 * @param {Date} end
 * @return {Function}
 */
export const forceGetShipments = (start, end) => async (dispatch, getState) => {
  const date = start;
  const onSuccess = payload => {
    dispatch({ type: SHIPMENTS_GET_RECEIVE, payload, date });
    return payload;
  };

  dispatch({ type: SHIPMENTS_GET_REQUEST });
  try {
    const {
      auth: { token }
    } = getState();
    const shipments = await ApiService.getShipments(token, {
      filter: "appointment",
      start,
      end
    });
    return onSuccess(shipments);
  } catch (error) {
    dispatch({ type: SHIPMENTS_GET_ERROR, error });
    return null;
  }
};

/**
 * Get all shipment jobs (Rollkarte) of a driver.
 * @param {Number} driverId
 * @return {Function}
 */
export const getShipmentJobsByDriver = driverId => async (
  dispatch,
  getState
) => {
  const onSuccess = payload => {
    dispatch({ type: SHIPMENTS_FROM_JOBS, payload });
    dispatch({
      type: SHIPMENT_DRIVER_JOBS_MAPPING,
      driverId,
      shipmentIds: payload.map(({ id }) => id)
    });
    return payload;
  };
  // no SHIPMENTS_GET_REQUEST needed
  try {
    const {
      auth: { token }
    } = getState();
    const {
      jobs: { shipments }
    } = await ApiService.getJobs(token, driverId);
    return onSuccess(shipments);
  } catch (error) {
    console.warn("Could not load jobs", error);
    return null;
  }
};

const ASSIGNMENT_STATUS = 22;
/**
 *
 * @param shipmentMap {Object} id -> shipment
 * @param shipmentIds {Array.<Number>} list of shipment ids
 * @return Array.<Shipment>
 */
const getShipmentsAndPackages = (shipmentMap, shipmentIds) => {
  const groupReferences = shipmentIds.map(
    id => shipmentMap[id].group_reference
  );
  return Object.values(shipmentMap).filter(shipment =>
    groupReferences.includes(shipment.group_reference)
  );
};

/**
 * Add flag to update all shipments in future,
 * if shipment instance is recurring.
 * @param shipments Array.<Shipment>
 * @return Array.<Shipment>
 */
const transformShipmentsToRecurrent = shipments =>
  shipments.map(shipment => {
    // originally `is_recurring`
    const { isRecurring } = shipment;
    if (!isRecurring) {
      return shipment;
    }
    return { ...shipment, changeRecurrenceInstance: true };
  });

/**
 * Assigns a driver to a list of shipments. Both assets are represented by id.
 * @param {Array.<Number>} shipmentIds
 * @param {Number} driverId
 * @param {Object} options
 * @param {Boolean} options.allRecurring
 * @return {Function}
 */
export const assignShipmentsToDriver = (
  shipmentIds,
  driverId,
  { allRecurring }
) => async (dispatch, getState) => {
  const shipmentEntityMap = getState().entities.shipments.entities;
  const shipments =
    allRecurring === true
      ? transformShipmentsToRecurrent(
          getShipmentsAndPackages(shipmentEntityMap, shipmentIds)
        )
      : getShipmentsAndPackages(shipmentEntityMap, shipmentIds);

  const onSuccess = () => {
    const successData = {
      shipmentIds,
      driverId
    };
    dispatch({ type: SHIPMENT_ASSIGN_DRIVER_SUCCESS, payload: successData });
    return successData;
  };
  dispatch({ type: SHIPMENT_ASSIGN_DRIVER_REQUEST });
  try {
    const {
      auth: { token }
    } = getState();
    const data = await ApiService.createJobForDriver(
      token,
      driverId,
      shipments,
      ASSIGNMENT_STATUS
    );
    return onSuccess(data);
  } catch (error) {
    dispatch({ type: SHIPMENT_ASSIGN_DRIVER_ERROR, error });
    return null;
  }
};

/**
 * Assigns a tour to a list of shipments. Both assets are represented by id.
 * @param {Array.<Number>} shipmentIds
 * @param {Number} tourId
 * @param {Object} options
 * @param {Boolean} options.allRecurring
 * @return {Function}
 */
export const assignShipmentsToTour = (
  shipmentIds,
  tourId,
  { allRecurring }
) => async (dispatch, getState) => {
  const shipmentEntityMap = getState().entities.shipments.entities;
  const shipments =
    allRecurring === true
      ? transformShipmentsToRecurrent(
          getShipmentsAndPackages(shipmentEntityMap, shipmentIds)
        )
      : getShipmentsAndPackages(shipmentEntityMap, shipmentIds);

  const onSuccess = () => {
    const successData = {
      shipmentIds,
      tourId
    };
    dispatch({ type: SHIPMENT_ASSIGN_TOUR_SUCCESS, payload: successData });
    return successData;
  };
  dispatch({ type: SHIPMENT_ASSIGN_TOUR_REQUEST });
  try {
    const {
      auth: { token }
    } = getState();
    const data = await ApiService.createJobForTour(
      token,
      tourId,
      shipments,
      ASSIGNMENT_STATUS
    );
    return onSuccess(data);
  } catch (error) {
    dispatch({ type: SHIPMENT_ASSIGN_TOUR_ERROR, error });
    return null;
  }
};

/**
 * Clear shipment assignment.
 * @param {Array.<Number>} shipmentIds
 * @return {Function}
 */
export const clearShipmentAssignment = shipmentIds => async (
  dispatch,
  getState
) => {
  const onSuccess = () => {
    const successData = {
      shipmentIds
    };
    dispatch({ type: SHIPMENT_CLEAR_ASSIGNMENT_SUCCESS, payload: successData });
    return successData;
  };
  dispatch({ type: SHIPMENT_CLEAR_ASSIGNMENT_REQUEST });
  try {
    const {
      auth: { token }
    } = getState();
    for (const shipmentId of shipmentIds) {
      await ApiService.deleteShipmentAssignment(token, shipmentId);
    }
    return onSuccess();
  } catch (error) {
    dispatch({ type: SHIPMENT_CLEAR_ASSIGNMENT_ERROR, error });
    return null;
  }
};
