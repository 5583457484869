import React from "react";
import PropTypes from "prop-types";
import RBButtonToolbar from "react-bootstrap/lib/ButtonToolbar";

const ButtonToolbar = ({ children, ...otherProps }) => (
  <RBButtonToolbar {...otherProps}>{children}</RBButtonToolbar>
);

ButtonToolbar.propTypes = {
  children: PropTypes.any
};

export default ButtonToolbar;
